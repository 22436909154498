<template>
  <div class="relative flex h-full flex-col overflow-hidden text-xs">
    <div
      v-if="actionItems.length > 0"
      ref="containerRef"
      class="grid grid-cols-1 overflow-y-auto pb-12"
      @scroll="handleScroll"
    >
      <div v-for="(item, idx) in actionItems" :key="idx">
        <CellActionNews :item="item" :is-focus-out="isFocusOut" :show-grouped-news-items="showGroupedNewsItems" />
      </div>
      <div v-if="loadMore" class="w-full bg-gray-900 px-4">
        <NewsFeedTemplateCard />
      </div>
    </div>
    <div v-else class="my-auto flex h-32 flex-col items-center justify-center">
      <BaseLoader v-if="loading" class="mx-auto" />
      <NoDataFound v-else class="mx-auto"></NoDataFound>
    </div>

    <div class="absolute bottom-24 right-4">
      <BaseButton v-if="showScrollToTop" @press="scrollToTop()" size="sm" class="z-50 w-10">
        <ArrowUpIcon class="inline h-3 w-3" />
      </BaseButton>
    </div>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, onMounted, ref, watch } from 'vue';
import CellActionNews from './cell/CellActionNews.vue';
import NewsFeedTemplateCard from '@/components/load_templates/NewsFeedTemplateCard.vue';
import useHttp from '@/composeables/http';
import { ArrowUpIcon } from '@heroicons/vue/20/solid';
import useEmitter from '@/composeables/emitter';
import { getScrollPositionFromBottom } from '@/composeables/helpers';
import { includes } from 'lodash';

const emitter = useEmitter();
const $http = useHttp();
const $store = useStore();
const emit = defineEmits(['news-items-updated']);
const props = defineProps({
  data: Array,
  loading: Boolean,
  paginate: Function,
  sourceShift: Boolean,
  leaveOffTime: Date,
  isFocusOut: Boolean,
  perPage: { type: Number, default: 100 }
});

watch(
  () => props.data,
  () => {
    loadMore.value = false;
    newsItems.value = props.data;
  }
);

//NEWS ITEMS
const newsItems = ref([]);
const teamNewsItems = ref([]);
const showGroupedNewsItems = ref([]);
const actionItems = computed(() => {
  const canEditByPost = {};
  const isStarredByPosed = {};

  if (!newsItems.value) {
    return [];
  }

  teamNewsItems.value.forEach(item => {
    canEditByPost[item.news_item_id] =
      item.starred_by_team_member && item.starred_by_team_member.email === $store.getters.session.user.email;

    if (!item.is_starred_by_my_team) {
      return;
    }
    isStarredByPosed[item.news_item_id] = item.starred_by_team_member;
  });
  let gotToCheckpoint = false;
  return newsItems.value.map(newsItem => {
    if (newsItem.similarNews) {
      newsItem.grouped = newsItem.grouped.map(groupedItem =>
        rebuildNewsItem(groupedItem, isStarredByPosed, canEditByPost, gotToCheckpoint)
      );
    }
    return rebuildNewsItem(newsItem, isStarredByPosed, canEditByPost, gotToCheckpoint);
  });
});
function rebuildNewsItem(newsItem, isStarredByPosed, canEditByPost, gotToCheckpoint) {
  const canEdit =
    canEditByPost[newsItem.id] === undefined ? true : canEditByPost[newsItem.id] || !isStarredByPosed[newsItem.id];
  const user = isStarredByPosed[newsItem.id] || { email: $store.getters.session.user.email };
  const name = user.first_name ? `${user.first_name} ${user.last_name}` : user.email;
  const hasCheckpoint =
    !gotToCheckpoint && props.leaveOffTime
      ? new Date(newsItem.timestamp).getTime() <= new Date(props.leaveOffTime).getTime()
      : undefined;

  if (hasCheckpoint) {
    gotToCheckpoint = true;
  }
  return {
    ...newsItem,
    hasCheckpoint,
    checkpoint: props.leaveOffTime,
    sourceShift: props.sourceShift,
    starredInfo: {
      isStarred: isStarredByPosed[newsItem.id] ? true : false,
      canChange: canEdit,
      name
    }
  };
}
function newsItemsUpdated() {
  emit('news-items-updated', newsItems.value);
}
onMounted(async () => {
  newsItems.value = props.data;

  const { data } = await $http.get('/data/team_news_items');

  teamNewsItems.value = data;

  emitter.$on('newsItemUpdated', async newsItem => {
    if (newsItem.action === 'hidden') {
      newsItems.value = newsItems.value.filter(item => {
        return item.id !== newsItem.news_item_id;
      });
      newsItemsUpdated();
      return;
    }

    if (newsItem.action === 'starred') {
      newsItems.value = newsItems.value.map(item => {
        if (item.id === newsItem.news_item_id) {
          return {
            ...item,
            is_starred_by_the_tie: newsItem.is_starred_by_the_tie,
            starred_by_the_tie_user: newsItem.user
          };
        }

        return item;
      });
      newsItemsUpdated();
      return;
    }

    if (newsItem.action === 'added tag' || newsItem.action === 'deleted tag') {
      newsItems.value = newsItems.value.map(item => {
        if (item.id === newsItem.news_item.id) {
          return newsItem.news_item;
        }

        return item;
      });
      newsItemsUpdated();
      return;
    }

    let found = false;
    teamNewsItems.value = teamNewsItems.value.map(item => {
      if (item.news_item_id === newsItem.team_news_item.news_item_id) {
        found = true;
        return {
          ...item,
          is_starred_by_my_team: newsItem.team_news_item.is_starred_by_my_team,
          starred_by_team_member: newsItem.starred_by_team_member
        };
      }

      return item;
    });

    if (!found) {
      const addedNewsItem = {
        ...newsItem.team_news_item,
        starred_by_team_member: newsItem.starred_by_team_member
      };

      teamNewsItems.value.push(addedNewsItem);
    }

    newsItemsUpdated();
  });
  emitter.$on('toggle:grouped-preview-items', id => {
    if (includes(showGroupedNewsItems.value, id)) {
      showGroupedNewsItems.value = showGroupedNewsItems.value.filter(item => item !== id);
    } else {
      showGroupedNewsItems.value.push(id);
    }
  });
});

//PAGINATION & SCROLLING
const loadMore = ref(false);
const page = ref(1);
const containerRef = ref(null);
const showScrollToTop = ref(false);
function handleScroll() {
  const position = getScrollPositionFromBottom(containerRef.value);
  if (position <= 50 && !props.loading && props.paginate) {
    loadMore.value = true;
    showScrollToTop.value = true;
    page.value++;
    props.paginate({ page: page.value, perPage: props.perPage });
  } else {
    loadMore.value = false;
  }

  //scrolled 10% of the scroll height
  if (containerRef.value.scrollTop >= containerRef.value.scrollHeight * 0.1) {
    showScrollToTop.value = true;
  } else {
    showScrollToTop.value = false;
  }
}
function scrollToTop() {
  showScrollToTop.value = false;
  containerRef.value.scroll({ top: 0, behavior: 'smooth' });
}
</script>
